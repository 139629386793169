<!-- [팝업] 기프티쇼 비즈 품목 검색 -->
<template>
  <b-modal
    v-model="showGiftSearch"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="기프티쇼 비즈 품목 검색"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="mt-25"
    >
      <b-col
        cols="12"
        md="10"
        class="d-flex align-items-center justify-content-center"
      >
        <b-form-input
          v-model="query.filterStr"
          class="el-def"
          placeholder="검색어를 입력하세요. (품목명 또는 브랜드명 입력)"
          @keyup.enter="fetchItems"
        />
      </b-col>
      <!-- Search -->
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="fetchItems"
        >
          조회
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-table
        primary-key="id"
        :items="resultData"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelected"
        @row-dblclicked="onRowDblClicked"
      >
        <template #cell(discountPrice)="data">
          <span class="text-nowrap">
            {{ data.value | $numberFormatter }}
          </span>
        </template>
        <template #cell(realPrice)="data">
          <span class="text-nowrap">
            {{ data.value | $numberFormatter }}P
          </span>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-pagination
          v-model="currPage"
          :total-rows="totalRecords"
          limit="10"
          per-page="10"
          align="center"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from '@axios'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'

export default {
  components: {
  },

  props: {
    showGiftSearch: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
      // fetchCodes()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Query Data
    const query = ref({
      filterStr: '',
    })
    // Query Data End.

    const choiceData = ref(null)

    // Main Table Config
    const resultData = ref([])
    const sortBy = ref('goodsCode')
    const isSortDesc = ref(false)
    const currPage = ref(1)
    const totalRecords = ref(0)
    const tableColumns = [
      { key: 'goodsName', label: '품목명', sortable: true },
      { key: 'brandName', label: '브랜드 이름', sortable: true },
      { key: 'discountPrice', label: '가격', sortable: true },
      { key: 'realPrice', label: '차감 포인트', sortable: true },
    ]
    watch([currPage], () => {
      fetchItems()
    })
    const onRowSelected = items => {
      const item = items[0]
      if (item) {
        choiceData.value = item
      }
    }
    const onRowDblClicked = item => {
      if (item) {
        choiceData.value = item
      }
      submit()
    }
    // Main Table Config End.

    const fetchItems = () => {
      if (query.value.filterStr.length < 2) {
        pushToast('warning', '검색어는 두 글자 이상 입력하세요. (품목명 또는 브랜드명)')
        return
      }
      axios.post('/fa/gift-product-search/search', {
        search: {
          filterStr: query.value.filterStr,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
        pagination: {
          number: currPage.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          resultData.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      if (choiceData.value === null) {
        pushToast('warning', '적용 항목을 선택하세요.')
        return
      }

      emit('close', choiceData.value)
    }

    return {
      query,
      close,
      submit,
      resultData,
      sortBy,
      currPage,
      isSortDesc,
      totalRecords,
      tableColumns,
      onRowSelected,
      onRowDblClicked,
      fetchItems,
    }
  },
}
</script>
