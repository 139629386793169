<!-- [팝업] 카카오 선포비 상품 등록 -->
<template>
  <b-modal
    v-model="isShow"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="카카오 선물하기 비즈 품목 등록"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="mt-25"
    >
      <b-col
        cols="12"
        md="10"
        class="d-flex align-items-center justify-content-center"
      >
        <b-form-input
          v-model="query.templateToken"
          class="el-def"
          placeholder="등록할 상품의 [템플릿 토큰]을 입력하세요 (필수)"
          @keyup.enter="fetchItems"
        />
      </b-col>
      <!-- Search -->
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="fetchItems"
        >
          조회
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="12"
      >
        <b-form-group
          label="품목명"
          label-for="goodsName"
        >
          <b-form-input
            id="goodsName"
            v-model="resultData.goodsName"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-form-group
          label="브랜드명"
          label-for="brandName"
        >
          <b-form-input
            id="brandName"
            v-model="resultData.brandName"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="가격"
          label-for="discountPrice"
        >
          <cleave
            id="discountPrice"
            v-model="resultData.discountPrice"
            :options="cleave.number"
            readonly
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="차감포인트"
          label-for="realPrice"
        >
          <cleave
            id="realPrice"
            v-model="resultData.realPrice"
            :options="cleave.number"
            readonly
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from '@axios'
import Cleave from 'vue-cleave-component'
import { clone } from '@core/utils/utils'
import { ref, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'

export default {
  components: {
    Cleave,
  },

  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
    })

    const cleave = ref({
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const resultInitState = {
      goodsCode: null,
      goodsNo: null,
      goodsName: null,
      goodsTypeNm: null,
      brandName: null,
      realPrice: 0,
      discountPrice: 0,
      goodsImgB: null,
    }
    const resultData = ref({ ...resultInitState })

    const resetResultData = () => {
      Object.assign(resultData.value, resultInitState)
    }

    // Query Data
    const query = ref({
      templateToken: '',
    })
    // Query Data End.

    const fetchItems = () => {
      resetResultData()

      if (!query.value.templateToken) {
        pushToast('warning', '[템플릿 토큰]은 필수 입력입니다.')
        return
      }
      axios.get(`/fa/gift-kakao-reg/search/${query.value.templateToken}`)
        .then(rs => {
          resultData.value = rs.data
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '데이터 조회에 실패하였습니다.'))
        })
    }

    const close = () => {
      emit('close', null)

      resetResultData()
      query.value.templateToken = ''
    }

    const submit = () => {
      if (!resultData.value.goodsCode) {
        pushToast('warning', '등록할 상품이 없습니다.')
        return
      }

      emit('close', clone(resultData.value))

      resetResultData()
      query.value.templateToken = ''
    }

    return {
      cleave,
      query,
      close,
      submit,
      resultData,
      fetchItems,
    }
  },
}
</script>
